import React from 'react';
import Main from '../Main/Main';



const Home = () => {
  

  return (
   
      <div>
     <Main></Main>
      </div>
   
  );
};

export default Home;
